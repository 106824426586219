import React from "react";
import { ListContainer, ListLayout, ListPagination } from "playground/cms/List";
import Button from "components/Button";
//import PropTypes from 'prop-types';

export function ResourceList(props) {
    const {
        type,
        list,
        id,
        onClick,
        onCreate,
        url,
        ListItem,
        sortable,
        children,
    } = props;
    return (
        <ListLayout className={"main-list"}>
            <ListLayout.Header>
                <ListLayout.Header.Icons>
                    <ListLayout.Header.Reload type={type} />
                </ListLayout.Header.Icons>
            </ListLayout.Header>
            <ListLayout.Content>
                <ListContainer
                    type={type}
                    list={list}
                    id={id}
                    onClick={onClick}
                    url={url}
                    className={"list overflow-scroll"}
                    Item={ListItem}
                    sortable={sortable}
                />
            </ListLayout.Content>
            <ListLayout.Footer>
                <ListPagination type={type} list={list} />
                <ListLayout.Footer.Actions>
                    {onCreate && (
                        <Button onClick={onCreate} disabled={!!id}>
                            Create
                        </Button>
                    )}
                </ListLayout.Footer.Actions>
            </ListLayout.Footer>
            {children}
        </ListLayout>
    );
}

ResourceList.propTypes = {};

ResourceList.defaultProps = {};

export default ResourceList;
