import React from "react";
import { FormLayout, FormSection } from "playground/cms/Form";
import Form from "containers/Form";
import Address from "../../fields/Address";
import Field from "containers/Field";
import { Property, PropertyGrid } from "../../fields/Properties";
import { EntryDescription } from "../Entries";
import { MediaField } from "../../fields/Media";
import ExtendedFields from "../../fields/ExtendedFields";
//import PropTypes from 'prop-types';

const selectFields = {
    title: false,
    slug: false,
    layout: false,
    seo: false,
    open_graph: false,
    translations: false,
    tags: false,
    type: false,
};

export function PropertyForm(props) {
    const { type, id } = props;
    return (
        <FormLayout.Fieldset pad>
            <EntryDescription {...props} />
            <FormSection name={"Dane kontaktowe"} variant={"default"}>
                <div className={"rows gap-sm"}>
                    <PropertyGrid>
                        <Property label={"Nazwa"}>
                            <Field id={"contact.name"} variant={"dense"} />
                        </Property>
                        <Property label={"Krótka nazwa"}>
                            <Field
                                id={"contact.short_name"}
                                variant={"dense"}
                            />
                        </Property>
                        <Property label={"Telefon"}>
                            <Field id={"contact.phone"} variant={"dense"} />
                        </Property>
                        <Property label={"Telefon 2"}>
                            <Field
                                id={"contact.phone_additional"}
                                variant={"dense"}
                            />
                        </Property>
                        <Property label={"E-mail"}>
                            <Field
                                id={"contact.email.email"}
                                variant={"dense"}
                            />
                        </Property>
                    </PropertyGrid>
                    <Field
                        id={"contact.address"}
                        label={"Adres"}
                        FieldComponent={Address}
                    />
                </div>
            </FormSection>
            {/*<Field id={"address"} label={"Adres"} FieldComponent={Address} />*/}
            <Field id={"website_url"} label={"Adres www"} />
            <MediaField id={"logo"} label={"Logo"} variant={"single"} />
            <Field id={"price_min"} label={"Cena od"} />
            {/*<Form type={type} id={id} selectFields={selectFields} />*/}
            <FormLayout.Section name={"Custom fields"} variant={"default"}>
                <ExtendedFields {...props} />
            </FormLayout.Section>
        </FormLayout.Fieldset>
    );
}

PropertyForm.propTypes = {};

PropertyForm.defaultProps = {};

export default PropertyForm;
