export default {
    "apartments.floors": {
        fields: {},
    },
    "apartments.investments": {},
    "apartments.buildings": {
        fields: {
            floors: ["apartments.floors"],
        },
        refs: {
            investment: "apartments.investments",
        },
    },
    "apartments.plans": {
        fields: { media: ["cms.media"] },
        override: ["layers"],
    },
    "apartments.flats": {
        fields: { media: ["cms.media"] },
    },
};
