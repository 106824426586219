import React, { useState } from "react";
//import PropTypes from 'prop-types';

import cn from "classnames";
import {
    useActions,
    useApiActions,
    useClick,
    useData,
    useFetch,
    useList,
    useListReload,
    useRegisterList,
} from "state/hooks";
import AceEditor from "react-ace-cdn";
import Field from "containers/Field";
import Preloader from "components/Preloader";
import IconButton from "components/IconButton";
import { removeCallback } from "state/actions/api";
import { resetList } from "state/actions/data";

import "./style.css";

function IntegrationLog(props) {
    const { isSelected } = props;
    const data = useData(props);
    const click = useClick(props);

    const created_at = new Date(data.created_at * 1000).toLocaleString();
    const time = Math.round(data.time * 100) / 100;

    return (
        <div
            className={cn("integration-log-entry", {
                "is-selected": isSelected,
            })}
            onClick={click}
        >
            <div>{created_at}</div>
            <div>{data.status}</div>
            <div>{time}s</div>
            <div>{data.url}</div>
        </div>
    );
}

function LogViewer(props) {
    const data = useData(props);

    if (data.error)
        return (
            <div className={"theme-dark"}>
                <pre>{JSON.stringify(data, null, 2)}</pre>
                <AceEditor
                    readOnly={true}
                    value={JSON.stringify(JSON.parse(data.request), null, 2)}
                    wrapEnabled={true}
                    mode={"json"}
                    theme={"solarized_dark"}
                    height={"500px"}
                />
            </div>
        );

    return (
        <div className={"theme-dark"}>
            <AceEditor
                readOnly={true}
                value={data.response}
                wrapEnabled={true}
                mode={data.response_format}
                theme={"solarized_dark"}
                height={"500px"}
            />
        </div>
    );
}

export function IntegrationLogs(props) {
    const { type, id } = props;
    const url = `cms/integrations/${id}/logs`;
    const list = id;
    const [logId, setId] = useState();

    const fetchProps = {
        url,
        type,
        list: id,
    };

    const { onDelete } = useApiActions({
        onDelete: removeCallback,
    });

    const { onClearList } = useActions({
        onClearList: resetList,
    });

    const handleDelete = () => {
        onDelete(
            () => {
                onClearList(type, list);
            },
            type,
            null,
            `cms/integrations/${id}/logs`
        );
    };

    const isPending = useFetch(fetchProps);
    useRegisterList(fetchProps);
    const [onReload] = useListReload(type, list);

    const { items } = useList(type, list);

    return (
        <>
            <Preloader visible={isPending} />
            <div className={"rows integration-logs"}>
                <div className={"cols integration-logs-toolbar"}>
                    <Field
                        id={"enable_logger"}
                        fieldType={"checkbox"}
                        label={"Enable logger"}
                    />
                    <IconButton icon={"mui-refresh"} onClick={onReload} />
                    <IconButton icon={"mui-clear"} onClick={handleDelete} />
                </div>
                <div className={"integration-logs-list"}>
                    {items.map((log) => (
                        <IntegrationLog
                            key={log}
                            id={log}
                            type={type}
                            onClick={setId}
                            isSelected={logId === log}
                        />
                    ))}
                </div>
                <div className={"integration-logs-viewer"}>
                    {logId && <LogViewer type={type} id={logId} />}
                </div>
            </div>
        </>
    );
}

IntegrationLogs.propTypes = {};

IntegrationLogs.defaultProps = {
    type: "app.integration-logs",
};

export default IntegrationLogs;
