import React from "react";
//import PropTypes from 'prop-types';
import MediaBrowser from "./MediaBrowser";
import MediaLibraryCreate from "./MediaLibraryCreate";
import MediaPreview from "./MediaPreview";
import { useFetch, useFilter, useRouteParam } from "state/hooks";
import { useDirectories, useMediaActions, useMediaList } from "./hooks";
import { useCurrentService } from "playground/cms/hooks";

export function MediaBrowserContainer(props) {
    const { select } = props;

    const [path, setPath] = useRouteParam("dir");
    const [preview, setPreview] = useRouteParam("m");

    const service = useCurrentService();

    const app = service?.type === "apartments" ? "apartments" : "cms";

    const directoriesPending = useFetch({
        url: `${app}/browser/dirs?batch=true`,
        type: "cms.media.directories",
        list: "browser",
    });

    const mediaPending = useFetch({
        url: `${app}/media`,
        type: "cms.media",
        list: "browser",
        filter: "browser",
    });

    const { value, onSet } = useFilter("cms.media/browser");

    const pending = directoriesPending || mediaPending;

    const directories = useDirectories(path);

    const media = useMediaList(path, !!value.search);
    const actions = useMediaActions();

    const { onUploadMedia } = actions;

    const handleUpload = (files) => {
        onUploadMedia("cms.media", "browser", path, files);
    };

    const createActions = {
        onUpload: handleUpload,
        onCreateDirectory: (name) => {
            const fullName = path ? `${path}/${name}` : name;
            return actions.onCreateDirectory(fullName);
        },
        onDeleteDirectory: path ? () => actions.onDeleteDirectory(path) : null,
        onImportVideo: (...args) => actions.onImportMedia("video", ...args),
        onImportPano: (...args) => actions.onImportMedia("pano", ...args),
        onSyncMedia: () => actions.onSyncMedia(path),
    };

    const clickHandler = select ? actions.onSelect : setPreview;

    return (
        <>
            <MediaBrowser
                select={select}
                isPending={pending}
                directories={directories}
                onChangePath={setPath}
                currentPath={path || ""}
                media={media}
                {...actions}
                onUpload={handleUpload}
                onClick={clickHandler}
                filterValue={value}
                onSetFilter={onSet}
                createButton={
                    <MediaLibraryCreate
                        {...createActions}
                        path={path}
                        isPending={pending}
                    />
                }
            />
            <MediaPreview
                open={!!preview}
                id={preview}
                onClose={() => setPreview(null)}
                onSave={actions.onSaveMedia}
                onDelete={actions.onDeleteMedia}
            />
        </>
    );
}

MediaBrowserContainer.propTypes = {};

MediaBrowserContainer.defaultProps = {
    select: false,
};

export default MediaBrowserContainer;
