import React, { useState } from "react";
import cn from "classnames";
import Field from "containers/Field";
import { useActions, useData, useFormField, useRouteParam } from "state/hooks";
import IconButton from "components/IconButton";
import Popover from "components/Popover";
import SelectList, { SelectListItem } from "playground/ui/SelectList";
import { OverlayForm } from "playground/cms/Form";
import Sections, { SectionIcon } from "playground/cms/views/Sections";
import { convertToTemplate } from "cms/state/actions/cms";

//import PropTypes from 'prop-types';

export function SectionTemplate(props) {
    const { type: entityType, id } = props;
    const data = useData(props);
    const { type, template, items } = useData(props);
    const [, setId] = useRouteParam("section");

    const templateData = useData({
        type: "cms.sections",
        id: template,
    });

    const { description: templateName } = templateData;

    const { onChange } = useFormField("template");

    const { onConvertToTemplate } = useActions({
        onConvertToTemplate: convertToTemplate,
    });

    const isTemplate = type === "view";
    const hasTemplate = !!template;
    const hasComponents = !!items?.length;

    const [selectTemplate, setSelectTemplate] = useState(false);

    const handleChangeTemplate = (newTemplate) => {
        onChange(newTemplate);
        setSelectTemplate(false);
    };

    const handleConvert = () => {
        onConvertToTemplate(entityType, id);
    };

    return (
        <>
            <div
                className={cn("section-template-btn", {
                    "has-template": !!template,
                })}
            >
                <SectionIcon id={hasTemplate ? template : id} />
                <div
                    onClick={!isTemplate ? () => setSelectTemplate(true) : null}
                >
                    {hasTemplate ? (
                        templateName
                    ) : (
                        <>{isTemplate ? "Szablon" : "Brak szablonu"}</>
                    )}
                </div>
                {/*<Field*/}
                {/*    id={"template"}*/}
                {/*    fieldType={"select"}*/}
                {/*    source={"api:sections?type=view"}*/}
                {/*    label={"Szablon sekcji"}*/}
                {/*    nullable={true}*/}
                {/*    */}
                {/*/>*/}

                <Popover
                    trigger={({ onClick }) => (
                        <IconButton
                            size={"small"}
                            icon={"mui-more_horiz"}
                            onClick={onClick}
                        />
                    )}
                    content={({ onClose }) => (
                        <>
                            <SelectList onClick={onClose}>
                                {hasTemplate && (
                                    <>
                                        <SelectListItem
                                            onClick={() => onChange(null)}
                                        >
                                            Usuń szablon
                                        </SelectListItem>
                                        <SelectListItem
                                            onClick={() => setId(template)}
                                        >
                                            Edytuj szablon
                                        </SelectListItem>
                                        {/*<SelectListItem>*/}
                                        {/*    Duplikuj szablon*/}
                                        {/*</SelectListItem>*/}
                                        {/*<SelectListItem>*/}
                                        {/*    Odłącz od szablonu*/}
                                        {/*</SelectListItem>*/}
                                    </>
                                )}
                                {!isTemplate &&
                                    !hasTemplate &&
                                    hasComponents && (
                                        <SelectListItem onClick={handleConvert}>
                                            Utwórz szablon
                                        </SelectListItem>
                                    )}
                            </SelectList>
                        </>
                    )}
                />
            </div>
            <OverlayForm
                onClose={() => setSelectTemplate(false)}
                open={selectTemplate}
                position={"center"}
            >
                {selectTemplate && (
                    <Sections
                        type={"cms.sections"}
                        list={"browser"}
                        create={false}
                        isBrowser={true}
                        filterValue={{ type: "view" }}
                        previewSection={id}
                        onClick={handleChangeTemplate}
                        onCloseSections={() => setSelectTemplate(false)}
                    />
                )}
            </OverlayForm>
        </>
    );
}

SectionTemplate.propTypes = {};

SectionTemplate.defaultProps = {};

export default SectionTemplate;
