import {
    resolveAlias,
    selectAliasedEntity,
    selectEntity,
} from "state/selectors/data";
import { selectRouteParam } from "state/selectors/router";
import { getIn } from "lodash-redux-immutability";
import { renderTemplate } from "cms/components/Template";
import { findRoot, selectExpanded } from "state/selectors/tree";
import { normalizeType } from "cms/util/connect";

const nullObj = {};

export function selectTree(store, items, type, list, rootType) {
    const expanded = selectExpanded(store, rootType, list);
    return items.map((id) => {
        let entity = selectEntity(store, type, id);
        let componentType = normalizeType(entity.element_type);
        return {
            id: entity.id,
            label: entity.style || componentType,
            type: componentType,
            disabled: entity.disabled,
            expanded: expanded.indexOf(entity.id) > -1,
            children: entity.components
                ? selectTree(store, entity.components, type, list, rootType)
                : [],
        };
    });
}

export function selectSettingsId(store) {
    return resolveAlias(store, "cms.settings", "@websiteSettings");
}

export function selectPageUrl(store, type, id, slug, service) {
    const page = selectEntity(store, type, id);
    service =
        service ||
        selectAliasedEntity(
            store,
            "console.services",
            selectRouteParam(store, "service")
        );
    const domain = selectEntity(
        store,
        "console.domains",
        service.canonical_domain
    );
    if (undefined === slug) slug = page.slug === "index" ? "" : page.slug;
    const hostname = domain.hostname || `${service.name}.zuu.tools`;
    if (!slug) return hostname;
    return `${hostname}/${slug}`;
}

const fieldsToTypes = {
    components: "cms.components",
    items: "cms.sections",
    gallery: "cms.sections",
    links: "cms.sections",
    consents: "cms.sections",
};

const typesToFields = {
    "cms.components": "components",
    "cms.sections": "items",
    "cms.links": "links",
    "cms.consents": "consents",
};

export function typeToField(type) {
    return typesToFields[type] || null;
}

export function fieldToType(field, type) {
    return fieldsToTypes[field] || null;
}

export function selectComponentRoot(store, type, id) {
    let root = findRoot(store, id, type);
    if (!root.id) return nullObj;
    return {
        type: fieldToType(root.key, type),
        id: root.id,
    };
}

export function selectLibraryId(store) {
    const service = selectRouteParam(store, "service");
    const serviceData = selectAliasedEntity(store, "console.services", service);
    return serviceData.library || null;
}

const ASSET_DOMAIN = process.env.REACT_APP_ASSETS_DOMAIN;

export function selectWebsiteCanonicalHost(store, id) {
    const service = selectAliasedEntity(store, "console.services", id);
    const domain = selectEntity(
        store,
        "console.domains",
        service.canonical_domain
    );
    if (domain.hostname && domain.active) return domain.hostname;
    if (service.name) return `http://${service.name}.${ASSET_DOMAIN}`;
    return null;
}
