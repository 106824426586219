import React from "react";
//import PropTypes from 'prop-types';

import Resources from "playground/cms/views/Resources";
import { FormLayout } from "playground/cms/Form";
import { ListLayout } from "playground/cms/List";
import { Search } from "playground/cms/List/Filter";
import TagsForm from "./TagsForm";

export function Tags(props) {
    const { type } = props;
    return (
        <Resources {...props}>
            <ListLayout>
                <ListLayout.Filter>
                    <Search type={type} name={"search"} />
                </ListLayout.Filter>
                <ListLayout.Header>
                    <ListLayout.Header.Icons prepend={true}>
                        <ListLayout.Header.Filter />
                    </ListLayout.Header.Icons>
                </ListLayout.Header>
            </ListLayout>
            <FormLayout>
                <FormLayout.Content replace={true}>
                    <FormLayout.Tab id={"tag"} label={"Tag"}>
                        <TagsForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Resources>
    );
}

Tags.propTypes = {};

Tags.defaultProps = {};

export default Tags;
