import { entityDeleteCallback } from "./delete";

export const openMediaLibrary = () => ({
    type: "CMS.MEDIA.OPEN",
});

export const closeMediaLibrary = () => ({
    type: "CMS.MEDIA.CLOSE",
});

export const openMediaSelect = (path, replace) => ({
    type: "CMS.MEDIA.OPEN_SELECT",
    payload: { path, replace },
});

export const openMediaSelectCallback = (callback, ...args) => {
    const action = openMediaSelect(...args);
    action.callback = callback;
    return action;
};

export const selectMedia = (id) => ({
    type: "CMS.MEDIA.SELECT",
    payload: { id },
});

export const createDirectory = (context) => (name) => ({
    type: "CMS.MEDIA.CREATE_DIRECTORY",
    payload: { name },
    context,
});

export const deleteDirectory = (context) => (name) => ({
    type: "CMS.MEDIA.DELETE_DIRECTORY",
    payload: { name },
    context,
});

export const importMedia = (context) => (type, url) => ({
    type: "CMS.MEDIA.IMPORT",
    payload: { type, url },
    context,
});

export const syncMedia = (context) => (dir) => ({
    type: "CMS.MEDIA.SYNC",
    payload: { dir },
    context,
});

export const saveMedia = (context) => () => ({
    type: "CMS.MEDIA.SAVE",
    payload: {},
    context,
});

export const deleteMedia = (context) => (cb, type, id, list) => {
    const url = "cms/browser/:id";
    return entityDeleteCallback(context)(cb, type, id, list, url);
};
