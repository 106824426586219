import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { FormLayout } from "playground/cms/Form";

export function TagsForm(props) {
    const {} = props;
    return (
        <>
            <FormLayout.Fieldset pad>
                <Field id={"title"} label={"Nazwa"} />
                <Field id={"icon"} label={"Ikona"} fieldType={"icon"} />
                <Field id={"external_id"} label={"Zewnętrzne ID"} />
            </FormLayout.Fieldset>
        </>
    );
}

TagsForm.propTypes = {};

TagsForm.defaultProps = {};

export default TagsForm;
