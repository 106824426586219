import React from "react";
import { FormLayout } from "playground/cms/Form";
import Resources from "playground/cms/views/Resources";
import BuildingsForm from "./BuildingsForm";

//import PropTypes from 'prop-types';

export function Buildings(props) {
    const {} = props;
    return (
        <Resources {...props}>
            <FormLayout>
                <FormLayout.Content replace={true}>
                    <FormLayout.Tab id={"building"} label={"Building"}>
                        <BuildingsForm {...props} />
                    </FormLayout.Tab>
                </FormLayout.Content>
            </FormLayout>
        </Resources>
    );
}

Buildings.propTypes = {};

Buildings.defaultProps = {};

export default Buildings;
