import React from "react";
import Field from "containers/Field";
import Popover from "components/Popover";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

import "./style.css";
import Button from "components/Button";

function AddressPopoverContent(props) {
    const { id, onClose } = props;

    return (
        <div className={"cols cols-2 gap-sm pad-md"}>
            <div className={"rows gap-sm"}>
                <div className={"cols gap-sm"}>
                    <Field id={`${id}.street`} label={"Ulica"} size={"small"} />
                    <Field id={`${id}.street_no`} label={"Nr"} size={"small"} />
                </div>
                <div className={"cols gap-sm"}>
                    <Field
                        id={`${id}.zip`}
                        label={"Kod pocztowy"}
                        size={"small"}
                    />
                    <Field
                        id={`${id}.city`}
                        label={"Miejscowość"}
                        size={"small"}
                    />
                </div>
                <div className={"cols gap-sm"}>
                    <Field
                        id={`${id}.region`}
                        label={"Województwo / region"}
                        size={"small"}
                    />
                </div>
                <div className={"cols cols-right"}>
                    <Button onClick={onClose}>OK</Button>
                </div>
            </div>
            {/*<Field id={`${id}.geolocation`} fieldType={"geolocation"} />*/}
        </div>
    );
}

function renderAddress(value) {
    const { street, street_no, zip, city, region, geolocation } = value;
    const line1 = [street, street_no].filter((v) => !!v).join(" ");
    const line2 = [zip, city].filter((v) => !!v).join(" ");
    const line3 = [region].filter((v) => !!v).join(" ");
    return [line1, line2, line3].filter((v) => !!v).join(", ");
}

function MapsImage(props) {
    let key =
        window.GOOGLE_API_KEY || "AIzaSyBfRYOKQaRqmPW_zf8xigOo8_GdW11fJUc";
    const { lat, lng } = props;
    if (!lat || !lng) return null;
    let src =
        `https://maps.googleapis.com/maps/api/staticmap` +
        `?center=${lat},${lng}&zoom=12` +
        `&markers=color:red%7Clabel:A%7C${lat},${lng}` +
        `&size=220x80&key=${key}`;
    return <img style={{ width: "100%" }} src={src} alt="map" />;
}

function GeoLocationComponent(props) {
    const { value, onSearch } = props;
    const str = [value?.lat, value?.lng].filter((v) => !!v).join(", ");

    if (str)
        return (
            <div onClick={onSearch} className={"geo-picker-map-img"}>
                <MapsImage lat={value.lat} lng={value.lng} />
            </div>
        );

    return (
        <div className={"cols"}>
            {str ? (
                <div>{str}</div>
            ) : (
                <div
                    className={
                        "rows rows-middle cursor-pointer hover@underline"
                    }
                    onClick={onSearch}
                >
                    znajdź na mapie
                </div>
            )}
            <IconButton
                icon={"mui-room"}
                onClick={onSearch}
                tooltip={"Zaznacz na mapie"}
            />
        </div>
    );
}

GeoLocationComponent.defaultProps = {
    value: {},
};

const anchorOrigin = {
    vertical: "top",
    horizontal: "left",
};

export function Address(props) {
    const { id, value, label, onChange } = props;
    const str = renderAddress(value);

    return (
        <Popover
            anchorOrigin={anchorOrigin}
            trigger={({ onClick }) => (
                <div className={"form-section variant-contained"}>
                    <div className={"cols cols-spread"}>
                        <div
                            onClick={onClick}
                            className={"rows gap-xs cursor-pointer"}
                        >
                            <div className={"o-60"}>{label}</div>
                            {str ? (
                                <strong className={"hover@underline"}>
                                    {str}
                                </strong>
                            ) : (
                                <div className={"hover@underline"}>
                                    Kliknij, aby dodać adres
                                </div>
                            )}
                        </div>
                        <Field
                            id={`${id}.geolocation`}
                            fieldType={"geolocation"}
                            GeoLocationComponent={GeoLocationComponent}
                        />
                    </div>
                </div>
            )}
            content={({ onClose }) => (
                <AddressPopoverContent {...props} onClose={onClose} />
            )}
        />
    );
}

Address.propTypes = {};

Address.defaultProps = {
    value: {},
};

export default Address;
