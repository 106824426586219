import React from "react";
import cn from "classnames";
//import PropTypes from 'prop-types';

import { thumbnail } from "util/thumbnail";
import IconButton from "components/IconButton";
import Icon from "components/Icon";

function FileName({ type, source, id, file, filename, extension, videoId }) {
    if (source === "youtube")
        return (
            <>
                <Icon>youtube-play</Icon> {videoId}
            </>
        );
    if (type === "video")
        return (
            <>
                <Icon>mui-play_circle</Icon> {filename}
            </>
        );
    return filename;
}

export const MediaItem = React.forwardRef((props, ref) => {
    const {
        id,
        file,
        type,
        video_id,
        source,
        active,
        noindex,
        isDragging,
        isPending,
        isSelected,
        onClick,
        onContextMenu,
    } = props;
    const filename = file ? file.split("/").pop() : "<no filename>";
    const extension = filename.split(".").pop();
    const src = thumbnail(file, [200]);
    return (
        <div
            ref={ref}
            className={cn("media-browser-media", {
                "is-disabled": !active,
                "is-dragging": isDragging,
                "is-pending": isPending,
                "is-selected": isSelected,
            })}
            onClick={onClick}
            onContextMenu={onContextMenu}
        >
            <div className={"media-browser-media-img"}>
                {src ? (
                    <img src={src} alt={filename} />
                ) : (
                    <div className={"media-browser-media-ext-badge"}>
                        {extension}
                    </div>
                )}
            </div>
            <div className={"media-browser-media-footer"}>
                <div className={"media-browser-media-label"}>
                    {noindex ? <Icon>mui-visibility_off</Icon> : null}
                    <FileName
                        type={type}
                        file={file}
                        filename={filename}
                        source={source}
                        videoId={video_id}
                    />
                </div>
                {onContextMenu && (
                    <IconButton
                        size={"small"}
                        icon={"mui-more_vert"}
                        onClick={onContextMenu}
                    />
                )}
            </div>
        </div>
    );
});

MediaItem.propTypes = {};

MediaItem.defaultProps = {
    file: "",
    type: "",
};

export default MediaItem;
