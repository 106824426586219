import { isBrowser } from "util/ssr";
import { thumbnailTypes } from "config/files";
import env from "util/env";
import thumbnailerConfig from "config/thumbnailer-presets";

const thumbnailerPresets = isBrowser()
    ? window.THUMBNAILER_PRESETS || thumbnailerConfig
    : thumbnailerConfig;

const apiEndpoint = env("THUMBNAIL_ENDPOINT") || "http://cms.d";

const upgradeHttps = (url) => {
    if (!isBrowser()) return url;
    if (
        window &&
        window.location &&
        window.location.href.indexOf("https:") === 0
    ) {
        return url.replace("http:", "https:");
    }
    return url;
};

export const thumbnail = (file, size) => {
    let preset = thumbnailerPresets ? thumbnailerPresets.sizes[size] : null;
    if (Array.isArray(size)) {
        preset = { w: size[0], h: size[1] };
    }
    if (!preset) preset = { w: 640, h: 480 };
    if (!file) return null;
    let fnParts = file.split(".");
    let extension = fnParts[fnParts.length - 1];
    if (thumbnailTypes.indexOf(extension.toLowerCase()) === -1) return null;
    if (file.indexOf("http") > -1) return file;
    const host = upgradeHttps(apiEndpoint);
    let url = host + "/thumb?file=" + encodeURIComponent(file);
    if (preset.w) url += "&w=" + preset.w;
    if (preset.h) url += "&h=" + preset.h;
    url += "#" + size;
    return url;
};

export const secureThumbnail = (file, size) => {
    let preset = thumbnailerPresets ? thumbnailerPresets.sizes[size] : null;
    if (Array.isArray(size)) {
        preset = { w: size[0], h: size[1] };
    }
    if (!preset) preset = { w: 640, h: 480 };
    if (!file) return null;
    let fnParts = file.split(".");
    let extension = fnParts[fnParts.length - 1];
    if (file.indexOf("_file") > -1) {
        return file + "&auth=" + localStorage.getItem("access_token");
    }
    if (thumbnailTypes.indexOf(extension.toLowerCase()) === -1) return null;
    if (file.indexOf("http") > -1) return file;
    const host = upgradeHttps(apiEndpoint);
    let url = host + "/thumb?file=" + encodeURIComponent(file);
    if (preset.w) url += "&w=" + preset.w;
    if (preset.h) url += "&h=" + preset.h;
    url += "#" + size;
    return url;
};
