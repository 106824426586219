import React from "react";
//import PropTypes from 'prop-types';

import Form from "containers/Form";
import Field from "containers/Field";
import { EntryDescription } from "playground/cms/views/Entries";
import ExtendedFields from "playground/cms/fields/ExtendedFields";
import { FormLayout } from "playground/cms/Form";
import { Property, PropertyGrid } from "playground/cms/fields/Properties";

export function AttractionsForm(props) {
    const { type, id } = props;
    return (
        <div className={"pad-md rows gap-md"}>
            <EntryDescription {...props} />

            <PropertyGrid>
                <Property label={"Link"}>
                    <Field label={null} id={"ex_link"} variant={"dense"} />
                </Property>
                <Property label={"Źródło"}>
                    <Field label={null} id={"ex_source"} variant={"dense"} />
                </Property>
            </PropertyGrid>

            <Field label={null} id={"icon"} fieldType={"icon"} />

            <FormLayout.Section name={"Custom fields"} variant={"default"}>
                <ExtendedFields {...props} />
            </FormLayout.Section>
        </div>
    );
}

AttractionsForm.propTypes = {};

AttractionsForm.defaultProps = {};

export default AttractionsForm;
