import React from "react";
import Field from "containers/Field";
import { FormSection } from "playground/cms/Form";
import uuid from "uuid/v4";
import TextField from "components/fields/text/TextField";
import IconButton from "components/IconButton";
//import PropTypes from 'prop-types';

function Mapping(props) {
    const { value, onChange } = props;
    const add = () => {
        onChange([...value, { id: uuid(), from: null, to: null }]);
    };
    const change = (id, key, v) => {
        onChange(
            value.map((item) => (item.id === id ? { ...item, [key]: v } : item))
        );
    };
    const remove = (id) => {
        onChange(value.filter((item) => item.id !== id));
    };
    return (
        <FormSection
            name={"Mapowanie zgód"}
            action={
                <div className={"cols"}>
                    <IconButton icon={"mui-add"} onClick={add} />
                </div>
            }
        >
            <div className={"rows gap-sm"}>
                {value &&
                    value.map((item, index) => (
                        <div className={"cols cols-middle grow gap-sm"}>
                            <div className={"cols cols-2 grow gap-xs"}>
                                <TextField
                                    onChange={(v) => change(item.id, "from", v)}
                                    value={item.from}
                                    label={"From"}
                                />
                                <TextField
                                    onChange={(v) => change(item.id, "to", v)}
                                    value={item.to}
                                    label={"To"}
                                />
                            </div>
                            <IconButton
                                icon={"mui-close"}
                                onClick={() => remove(item.id)}
                            />
                        </div>
                    ))}
            </div>
        </FormSection>
    );
}

export function BetasiNewsletter(props) {
    const {} = props;
    return <Field id={"consent_map"} FieldComponent={Mapping} />;
}

BetasiNewsletter.propTypes = {};

BetasiNewsletter.defaultProps = {};

export default BetasiNewsletter;
