import React from "react";
import { enforceHttps } from "util/uri";
import env from "util/env";
import VideoPlayer from "joynt/components/VideoPlayer";

//import PropTypes from 'prop-types';

export default function Video(props) {
    const { file, source, video_id } = props;
    const host = enforceHttps(env("FILES_CDN"));
    const playbackUrl = `${host}${file}`;

    if (source === "youtube")
        return (
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${video_id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        );

    return <VideoPlayer url={playbackUrl} />;
}

Video.propTypes = {};
Video.defaultProps = {};
