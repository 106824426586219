import React from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field/Field";
import { MediaField } from "../../../fields/Media";

import SerpPreview from "react-serp-preview";
import OpengraphReactComponent from "opengraph-react";
import Card from "@material-ui/core/Card";

import { selectPageUrl } from "cms/state/selectors/cms";
import { useSelector } from "react-redux";

import { selectOpenGraph, selectSeo } from "../../../state/selectors/seo";
import { FormSection } from "playground/cms/Form";

function SeoPreview({ type, id }) {
    const seo = useSelector((store) =>
        selectSeo(store, { entityType: type, entityId: id })
    );
    const url = useSelector((store) => selectPageUrl(store, type, id));
    return (
        <div className={"rows gap-sm"}>
            <Card className={"pad-sm bg-white"}>
                <SerpPreview
                    title={seo.title || ""}
                    metaDescription={seo.description || ""}
                    url={url}
                />
            </Card>
        </div>
    );
}

function OpenGraphPreview({ type, id }) {
    const result = useSelector((store) => selectOpenGraph(store, type, id));
    return (
        <OpengraphReactComponent
            dontMakeCall={true}
            results={result}
            component={"small"}
        />
    );
}

export function PageSeo(props) {
    return (
        <div className={"rows gap-md"}>
            <Field
                id={"seo.noindex"}
                label={"Pozwalaj wyszukiwarkom na indeksowanie tej podstrony"}
                fieldType={"checkbox"}
                negate={true}
            />

            <FormSection name={"Google"}>
                <div className={"rows gap-md"}>
                    <SeoPreview {...props} />

                    <div className={"rows gap-sm"}>
                        <Field id={"seo.title"} label={"Tytuł SEO"} />
                        <Field id={"seo.description"} label={"Opis SEO"} />
                        <Field id={"seo.keywords"} label={"Słowa kluczowe"} />
                    </div>
                </div>
            </FormSection>

            <FormSection name={"OpenGraph (Facebook, Linkedin, X etc.)"}>
                <div className={"rows gap-md"}>
                    <OpenGraphPreview {...props} />

                    <div className={"rows gap-sm"}>
                        <Field id={"open_graph.title"} label={"Tytuł"} />
                        <Field id={"open_graph.description"} label={"Opis"} />
                        <MediaField
                            id={"open_graph.media"}
                            label={"Obrazek"}
                            fieldType={"media"}
                        />
                    </div>
                </div>
            </FormSection>
        </div>
    );
}

PageSeo.propTypes = {};

PageSeo.defaultProps = {};

export default PageSeo;
