import React from "react";
//import PropTypes from 'prop-types';
import MediaPreview from "./MediaPreview";
import { useData, usePending, useRouteParam } from "state/hooks";
import { thumbnail } from "util/thumbnail";
import { enforceHttps } from "util/uri";
import env from "util/env";
import AggregateRoot from "containers/AggregateRoot";
import fileTypes from "components/media-preview/fileTypes";

export function MediaPreviewContainer(props) {
    const { id, type, onSave, onClose, onDelete } = props;
    const data = useData({ type, id });
    const [, showPreview] = useRouteParam("preview");
    const isPending = usePending(type);

    const onShowPreview = () => {
        showPreview([type, id].join("/"));
    };
    const onDownload = () => {
        const host = enforceHttps(env("FILES_CDN"));
        const url = `${host}${data.file}`;
        window.open(url);
    };

    const handleDelete = () => {
        const cb = () => {
            showPreview(null);
            onClose();
        };
        onDelete(cb, type, id);
    };

    const preview = thumbnail(data.file, [200, 150]);
    const PreviewComponent = fileTypes[data.type] || fileTypes.default;

    //return <pre>{JSON.stringify(data, null, 2)}</pre>;

    return (
        <AggregateRoot type={type} id={id} disableSubmit={true}>
            <MediaPreview
                {...data}
                {...props}
                isPending={isPending}
                fileType={data.type}
                preview={preview}
                onShowPreview={onShowPreview}
                onDownload={onDownload}
                onDelete={handleDelete}
            >
                <PreviewComponent {...data} />
            </MediaPreview>
        </AggregateRoot>
    );
}

MediaPreviewContainer.propTypes = {};

MediaPreviewContainer.defaultProps = {
    type: "cms.media",
};

export default MediaPreviewContainer;
