import React from "react";
import { FormLayout } from "playground/cms/Form";
import Field from "containers/Field";

//import PropTypes from 'prop-types';

function FloorItem(props) {
    const { type, id } = props;
    return (
        <div className={"cols cols-2 gap-sm"}>
            <Field
                id={"code_part"}
                entityType={type}
                entityId={id}
                label={"Kod"}
            />
            <Field
                id={"name"}
                entityType={type}
                entityId={id}
                label={"Nazwa"}
            />
        </div>
    );
}

FloorItem.defaultProps = {
    type: "apartments.floors",
};

function FloorsCollection(props) {
    const { value, onCreate, onChange } = props;

    return (
        <FormLayout.Section
            name="Piętra"
            action={<div onClick={() => onCreate()}>add</div>}
        >
            <div className={"rows gap-sm"}>
                {value.map((item, index) => (
                    <FloorItem key={item} id={item} />
                ))}
            </div>
        </FormLayout.Section>
    );
}

FloorsCollection.defaultProps = {
    value: [],
};

function RenderFloorsCollection(props) {
    return <FloorsCollection {...props} />;
}

export function BuildingsForm(props) {
    const {} = props;
    return (
        <FormLayout.Fieldset pad>
            <Field id={"name"} label={"Nazwa"} />
            <Field id={"code_part"} label={"Kod"} />

            <Field
                id={"investment"}
                fieldType={"select"}
                label={"Inwestycja"}
                source={"api:investments"}
            />

            <Field
                id={"development_stage"}
                fieldType={"select"}
                label={"Etap inwestycji"}
                source={"schema:data.development-stage"}
            />
            <Field
                id={"floors"}
                label={"Piętra"}
                fieldType={"collection"}
                CollectionRenderer={RenderFloorsCollection}
            />
        </FormLayout.Fieldset>
    );
}

BuildingsForm.propTypes = {};

BuildingsForm.defaultProps = {};

export default BuildingsForm;
