import React from "react";
//import PropTypes from 'prop-types';

import { FormLayout } from "playground/cms/Form";
import { useData } from "state/hooks";
import Field from "containers/Field";
import { useHasScope } from "playground/console/UserAccess";
import { SCOPE_WEBSITES_DEV } from "config/access-scopes";

export function SectionSettings(props) {
    const { nav_enable, used_in, type } = useData(props);
    const devAccess = useHasScope(SCOPE_WEBSITES_DEV);

    return (
        <>
            <FormLayout.Section name={"Responsywność"}>
                <Field
                    id={"breakpoints"}
                    fieldType={"select"}
                    source={"schema:data.device-visibility"}
                    label={"Urządzenia"}
                    variant={"filter"}
                />
            </FormLayout.Section>
            <FormLayout.Section name={"Nawigacja"}>
                <FormLayout.Fieldset>
                    <Field
                        id={"nav_enable"}
                        fieldType={"checkbox"}
                        label={"Pokaż w menu sekcji"}
                    />
                    {nav_enable && (
                        <>
                            <Field
                                id={"nav_label"}
                                fieldType={"text"}
                                label={"Nazwa w menu"}
                            />
                        </>
                    )}
                    <Field
                        id={"slug"}
                        fieldType={"text"}
                        label={"Slug URL sekcji"}
                    />
                    {devAccess && (
                        <div className={"cols cols-2 gap-sm"}>
                            <Field
                                id={"anchor_before"}
                                fieldType={"text"}
                                label={"Anchor before"}
                            />
                            <Field
                                id={"anchor_after"}
                                fieldType={"text"}
                                label={"Anchor after"}
                            />
                        </div>
                    )}
                </FormLayout.Fieldset>
            </FormLayout.Section>
            {devAccess && (
                <FormLayout.Section name={"Dev"}>
                    <FormLayout.Fieldset>
                        <Field id={"template_id"} label={"template_id"} />

                        {type === "placeholder" ? (
                            <Field
                                id={"placeholder_id"}
                                label={"placeholder_id"}
                                nullable={true}
                            />
                        ) : (
                            <Field
                                id={"target_placeholder_id"}
                                label={"target_placeholder_id"}
                                nullable={true}
                            />
                        )}
                        <Field
                            id={"type"}
                            fieldType={"select"}
                            source={"schema:sections.types"}
                            label={"Typ"}
                            nullable={false}
                        />
                        {type === "view" && (
                            <>
                                <Field
                                    id={"reference_id"}
                                    fieldType={"select"}
                                    source={"api:sections.library"}
                                    label={"Szablon z biblioteki"}
                                    nullable={true}
                                />
                                <Field
                                    id={"library_export"}
                                    fieldType={"checkbox"}
                                    label={
                                        "Pozwalaj na dołączanie sekcji z biblioteki"
                                    }
                                />
                            </>
                        )}
                    </FormLayout.Fieldset>
                </FormLayout.Section>
            )}
            {devAccess && (
                <FormLayout.Section name={"Section usage"}>
                    <pre>{JSON.stringify(used_in, null, 2)}</pre>
                </FormLayout.Section>
            )}
        </>
    );
}

SectionSettings.propTypes = {};

SectionSettings.defaultProps = {};

export default SectionSettings;
