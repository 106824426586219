import { selectPageUrl } from "cms/state/selectors/cms";
import { selectAliasedEntity, selectEntity } from "state/selectors/data";
import { thumbnail } from "util/thumbnail";
import { getIn } from "lodash-redux-immutability";
import { renderTemplate } from "cms/components/Template";

export function selectSeo(store, props) {
    const settings = selectAliasedEntity(
        store,
        "cms.settings",
        "@websiteSettings"
    );
    const page = selectEntity(store, props.entityType, props.entityId);
    const seo = page.seo || {};
    let vars = {
        name: getIn(settings, ["contact", "name"]),
        city: getIn(settings, ["contact", "address", "city"]),
        title: page.title,
        lang: "pl",
    };
    let pageTitleTemplate = settings.seo_title_default_template || "{title}";
    let seoTitleFormat = settings.seo_title_template || "{title} - {name}";
    let title = seo.title || renderTemplate(pageTitleTemplate, vars);
    vars.title = title;
    title = renderTemplate(seoTitleFormat, vars);
    return {
        title,
        description: seo.description,
    };
}

export function selectOpenGraph(store, type, id) {
    const seo = selectSeo(store, { entityType: type, entityId: id });
    const url = selectPageUrl(store, type, id);
    const { open_graph } = selectEntity(store, type, id);

    const media = selectPageMedia(store, type, id);
    const file = media?.file || null;
    const image = file ? thumbnail(file, "md") : null;

    return {
        hybridGraph: {
            site_name: open_graph?.title || seo?.title,
            //title: open_graph?.title || seo?.title,
            description: open_graph?.description || seo?.description,
            image,
            url,
        },
    };
}

export function findImage(store, mediaIds = []) {
    for (let i = 0; i < mediaIds.length; i++) {
        const media = store?.data?.["cms.media"]?.[mediaIds[i]] || null;
        if (media?.type === "image") return media;
    }
    return null;
}

export function selectPageMedia(store, type, id) {
    const { sections, media, open_graph } = selectEntity(store, type, id);
    const ogImage = findImage(store, open_graph?.media || []);
    if (ogImage) return ogImage;
    const pageImage = findImage(store, media || []);
    if (pageImage) return pageImage;
    if (!sections?.length) return null;

    for (let i = 0; i < sections.length; i++) {
        let section = selectEntity(store, "cms.sections", sections[i]);
        const sectionImage = findImage(store, section?.media || []);
        if (sectionImage) return sectionImage;
    }

    return null;
}
