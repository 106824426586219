import React, { useState } from "react";
import {
    useApiActions,
    useData,
    useHttpRequest,
    useList,
    usePending,
    useUiFlag,
} from "state/hooks";
import { createSnapshot, restoreSnapshot } from "cms/state/actions/services";
import useFetch from "state/hooks/useFetch";
import Button from "@material-ui/core/Button";
import TextField from "components/fields/text/TextField";
import ControlledSelect from "components/fields/select/ControlledSelect";
import { CircularProgress } from "@material-ui/core";
import { FormLayout, FormSection } from "playground/cms/Form";
import IconButton from "components/IconButton";
import Preloader from "components/Preloader";
import Checkbox from "components/fields/checkbox/Checkbox";
import { useHasScope } from "../../../UserAccess";
import { SCOPE_MASTER } from "config/access-scopes";
//import PropTypes from 'prop-types';

const snapshotNamespaces = [
    "production-ecs",
    "michal-dev",
    "michal-dev-indigo",
    "szczepan-dev",
    "szczepan-work-dev",
    "staging-zaffre",
    "szczepan-work-zaffre",
];

const snapshotNsOptions = snapshotNamespaces.map((ns) => ({
    id: ns,
    label: ns,
}));

function ProgressButton(props) {
    const { pending, ...rest } = props;
    const progressIcon = <CircularProgress size={16} color={"inherit"} />;
    return (
        <Button
            {...rest}
            disabled={pending}
            endIcon={pending && progressIcon}
        />
    );
}

function Migrations(props) {
    const { id } = props;

    const [post, pending] = useHttpRequest({
        url: `cms/migrations/${id}`,
        id: `cms.migrations.${id}`,
    });

    return (
        <FormLayout.Section name={"Migrations"}>
            <div className={"rows gap-md"}>
                <div className={"cols gap-md cols-spread cols-middle"}>
                    <div>Don't use it on production content</div>
                    <ProgressButton
                        pending={pending}
                        onClick={() => post({})}
                        variant={"contained"}
                    >
                        Run migration
                    </ProgressButton>
                </div>
                <MoveMediaPaths id={id} />
            </div>
        </FormLayout.Section>
    );
}

function MoveMediaPaths(props) {
    const { id } = props;

    const [target, setTarget] = useState("");
    const [copyMedia, setCopyMedia] = useState(false);

    const [moveMedia, moveMediaPending] = useHttpRequest({
        url: `cms/media/move/${id}`,
        id: `cms.moveMedia.${id}`,
        method: "post",
    });

    return (
        <div className={"cols gap-sm"}>
            <TextField
                label={"Target s3 media path"}
                value={target}
                onChange={setTarget}
            />
            <div>
                <Checkbox
                    value={copyMedia}
                    onChange={setCopyMedia}
                    label={"Copy media on s3"}
                />
            </div>
            <div>
                <ProgressButton
                    pending={moveMediaPending}
                    onClick={() => moveMedia({ target, copy: copyMedia })}
                    variant={"contained"}
                >
                    Move media
                </ProgressButton>
            </div>
        </div>
    );
}

function SnapshotItem(props) {
    const data = useData(props);
    return (
        <div>
            {data.id} {data.created_at}
        </div>
    );
}

export function CmsSnapshots(props) {
    const {} = props;
    const [snapshotNs, setSnapshotNs] = useUiFlag("cms.snapshots.ns");
    const { name: serviceName, content_source: cmsId } = useData(props);
    const [from, setFrom] = useState(null);
    const fullAccess = useHasScope(SCOPE_MASTER);

    const { onCreateSnapshot, onRestoreSnapshot } = useApiActions({
        onCreateSnapshot: createSnapshot,
        onRestoreSnapshot: restoreSnapshot,
    });

    const url = snapshotNs
        ? `console/cms/${cmsId}/snapshots?ns=${snapshotNs}`
        : `console/cms/${cmsId}/snapshots`;

    const pending = useFetch({
        type: "console.cms.snapshots",
        list: cmsId,
        url,
    });

    const restorePending = usePending([`${cmsId}.restore`, `${cmsId}.dump`]);

    const isPending = pending || restorePending;

    const { items: snapshots } = useList("console.cms.snapshots", cmsId);
    const latestSnapshot = snapshots.filter((s) => !s.includes("/latest"))[0];
    const latest = useData({
        type: "console.cms.snapshots",
        id: latestSnapshot,
    });

    const [showMore, setShowMore] = useState(false);

    const time = latest
        ? new Date(latest.created_at * 1000).toLocaleString()
        : null;

    return (
        <div className={"rows pad-md gap-md"}>
            <div>{serviceName}</div>
            <FormSection
                name={latest ? `Ostatni punkt ${time}` : "..."}
                icon={"mui-history"}
                action={
                    <IconButton
                        icon={"mui-more_vert"}
                        onClick={() => setShowMore(!showMore)}
                    />
                }
            >
                <div className={"rows gap-md"}>
                    <div>Po przywróceniu należy przeładować CMS (F5)</div>
                    <div className={"cols gap-sm"}>
                        <Button
                            onClick={() => onCreateSnapshot(cmsId)}
                            disabled={!cmsId || isPending}
                            color={"primary"}
                            variant={"contained"}
                        >
                            Utwórz punkt przywracania
                        </Button>
                        <Button
                            onClick={() =>
                                onRestoreSnapshot(cmsId, latestSnapshot)
                            }
                            color={"default"}
                            variant={"contained"}
                            disabled={isPending || !cmsId || !latestSnapshot}
                        >
                            Przywróć
                        </Button>
                    </div>
                    {showMore && (
                        <div className={"rows gap-sm"}>
                            <ControlledSelect
                                value={snapshotNs}
                                onChange={(v) => setSnapshotNs(v)}
                                options={snapshotNsOptions}
                                label={"Namespace"}
                            />
                            <ControlledSelect
                                value={from}
                                onChange={(v) => setFrom(v)}
                                options={
                                    snapshots.map((s) => ({
                                        id: s,
                                        label: s,
                                    })) || []
                                }
                                label={"Restore from"}
                            />
                            <div className={"cols"}>
                                <TextField
                                    onChange={(v) => setFrom(v)}
                                    value={from || ""}
                                    label={"Restore from"}
                                />
                                <Button
                                    onClick={() => {
                                        onRestoreSnapshot(cmsId, from);
                                    }}
                                    disabled={!from || restorePending}
                                >
                                    {restorePending
                                        ? "Restoring"
                                        : "Restore from snapshot"}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <Preloader visible={isPending} />
            </FormSection>

            {fullAccess && <Migrations id={props.id} />}
        </div>
    );
}

CmsSnapshots.propTypes = {};

CmsSnapshots.defaultProps = {};

export default CmsSnapshots;
